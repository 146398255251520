<script lang="ts">
import { ComponentPublicInstance, defineComponent, ref } from 'vue'

import FerrariLogo from '@/assets/logo.svg'

export type PreloaderPublicInstance = ComponentPublicInstance & {
  visible: boolean
}

export default defineComponent({
  name: 'ThePreloader',
  components: {
    FerrariLogo
  },
  setup (props, { expose }) {
    const visible = ref(true)

    expose({ visible })

    return {
      visible
    }
  }
})
</script>

<template>
  <div id="app-loading-container">
    <Transition>
      <div v-if="visible" id="app-loading">
        <FerrariLogo class="logo" />
      </div>
    </Transition>
  </div>
</template>

<style lang="scss">
#app-loading {
  position: absolute;
  z-index: $z-preloader;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background-color: $c-black;
}
</style>

<style lang="scss" scoped>
.logo {
  height: rem(42px);
  animation: logo-pulse 1.2s $ease-out-sine 0s infinite;

  @screen lg {
    height: rem(60px);
  }
}

@keyframes logo-pulse {
  0% { transform: scale(0.9) }
  50% { transform: scale(1.1) }
  100% { transform: scale(0.9) }
}
</style>
